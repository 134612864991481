<template>
  <label class="checkbox-container" :class="{ desktop, disabled }">
    <div class="content-before">
      <slot name="start"></slot>
    </div>
    <input
      type="checkbox"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      :disabled="disabled"
    />
    <span class="checkmark"></span>
    <div class="content-after">
      <slot name="end"></slot>
    </div>
  </label>
</template>

<script>
import { toRefs } from 'vue'

export default {
  name: 'UiCheckbox',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    desktop: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    return toRefs(props)
  }
}
</script>

<style lang="scss" scoped>
.checkbox-container {
  position: relative;
  display: flex;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    flex-shrink: 0;
    position: relative;
    height: 19px;
    width: 19px;
    background: $white;
    border: 2px solid $grey;
    border-radius: 3px;
    transition: 200ms;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      bottom: 4px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:checked + .checkmark {
    background-color: $main-blue;
    border-color: $main-blue;

    &::after {
      display: block;
    }
  }

  &.disabled input:checked + .checkmark {
    background-color: $grey;
    border-color: $grey;
    cursor: auto;
  }

  .content-before > :deep(*) {
    margin-right: 16px;
  }

  .content-after > :deep(*) {
    margin-left: 16px;
  }

  // DESKTOP VIEW
  &.desktop .checkmark {
    border: 1px solid $medium-light-grey;
    height: 17px;
    width: 18px;
    margin-top: 2px;
  }

  &.desktop .checkmark::after {
    height: 8px;
  }
}
</style>
