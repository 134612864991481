<template>
  <button
    :class="{
      primary: btnType === 'primary',
      secondary: btnType === 'secondary',
      warning: btnType === 'warning',
      danger: btnType === 'danger',
      confirm: btnType === 'confirm',
      loading: isLoading
    }"
    :disabled="isDisabled"
    @click.stop="$emit('click')"
  >
    <span v-if="isLoading" class="button-spinner"> </span>
    <span class="label" v-if="label">{{ label }}</span>
    <span class="label">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'UiButton',
  emits: ['click'],
  props: {
    btnType: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

%desktop-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  font-family: 'IBM Plex Sans', 'sans-serif';
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  transition: all 150ms ease-in-out;
  white-space: nowrap;

  &.loading {
    pointer-events: none;
    flex-direction: column;
  }

  &.loading .label {
    height: 0;
    overflow: hidden;
  }

  .label {
    display: flex;
    align-items: center;
  }
}

.primary {
  @extend %desktop-button;
  color: $white;
  background: $main-blue;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    background: $medium-light-grey;
    border-color: $medium-light-grey;
    color: $grey;
    cursor: default;
    pointer-events: none;
  }
}

.secondary {
  @extend %desktop-button;
  background: $white;
  border: 1px solid $light-blue;
  color: $main-blue;

  &:hover {
    background: $light-blue;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    border: 1px solid $medium-light-grey;
    color: $grey;
    cursor: default;
    pointer-events: none;
  }
}

.warning {
  @extend %desktop-button;
  background: $white;
  border: 1px solid $light-blue;
  color: $main-red;

  &:hover {
    background: $light-blue;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    border: 1px solid $grey;
    color: $grey;
    cursor: default;
    pointer-events: none;
  }
}

.danger {
  @extend %desktop-button;
  background: $main-red;
  border: 1px solid $main-red;
  color: $white;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    background: $medium-light-grey;
    border-color: $medium-light-grey;
    color: $grey;
    cursor: default;
    pointer-events: none;
  }
}

.confirm {
  @extend %desktop-button;
  background: $main-green;
  border: 1px solid $main-green;
  color: $white;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    background: $medium-light-grey;
    border-color: $medium-light-grey;
    color: $grey;
    cursor: default;
    pointer-events: none;
  }
}

.button-spinner {
  display: inline-block;
  border: 2px solid #8bb1ff;
  border-top: 2px solid $white;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  animation: spin 500ms linear infinite;
}
</style>
